import React, { useContext } from 'react';
import { Callout } from 'components';
// import { Row, Col } from 'react-flexbox-grid';
import { appContext } from 'providers/appProvider';
import DownloadBlock from 'components/DownloadBlock';
import ColorBlock from 'components/Content/ColorBlock';

function NutritionDownloadRecipes({ className, ...props }) {
  const { appConfigs } = useContext(appContext);
  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };
  return (
    <Callout className={`${className}`}>
      <ColorBlock yellow id="colorblock" className="mt-15 mb-20">
        <DownloadBlock
          DownloadFile="NS-05402___Nutrition_Direct_Recipe_Card_Library.pdf"
          LinkCaption="DOWNLOAD RECIPES"
          ThumbnailImage="Nutrition_Direct_Recipes_thumb.png"
          className="btn-nd-download-recipes yellow-button"
          secondary
          secondaryDownloadFile="NS-05405___Nutrition_Direct_Recipe_Card_Library_-_Spanish.pdf"
          secondaryLinkCaption="DOWNLOAD SPANISH RECIPES"
          secondaryClassName="btn-nd-download-recipes-spanish yellow-button"
        >
          <h2 class="txt-uppercase">RECIPE BOOK</h2>
          <p>
            Recipes include breakfast, lunch and dinner, side dishes, and
            desserts.
          </p>
        </DownloadBlock>
      </ColorBlock>
    </Callout>
  );
}

export default NutritionDownloadRecipes;
